/**=====================
    Switch CSS Start
==========================**/
.order-discussion {
    li {
      display: block;
      margin-bottom: 25px;
      padding-bottom: 19px;
      position: relative;
      border-bottom: 1px solid $border-color;

      .link-dislike-box {
        display: flex;
        gap: 13px;
        position: absolute;
        top: 0;
        right: 0;

        body[dir="rtl"] & {
          right: unset;
          left: 0;
        }

        @include mq-max(sm) {
          position: relative;
          margin-top: 10px;
          justify-content: flex-end;
        }

        li {
          margin: 0;
          padding: 0;
          border: unset;

          a {
            span {
              display: flex;
              align-items: center;
              gap: 4px;
              color: rgba($content-color, 0.6);

              svg {
                font-size: 16px;
              }
            }
          }
        }
      }

      .message-box {
        display: flex;
        gap: 15px;
        margin-bottom: 15px;
        width: 93%;

        @media (max-width: 1480px) {
          width: 89%;
        }

        @media (max-width: 684px) {
          width: 85%;
        }

        @include mq-max(sm) {
          width: 100%;
        }

        h5 {
          border: 1px solid #eee;
          background-color: #f8f8f8;
          text-transform: capitalize;
          font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
          padding-inline: 7px;
          width: auto;
          height: 30px;
          @include flex_common;
          margin-bottom: 0;
        }
      }

      
      h6 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

     
    }
  }

