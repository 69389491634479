/**=====================
     Reset css start
==========================**/
.font-roboto {
  font-family: $public-sans;
}

/*====== Padding css starts ======*/
$i: 0;

@while $i<=50 {
  .p-#{$i} {
    padding: #{$i}px;
  }

  $i: $i+5;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;

@while $i<=50 {
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;

@while $i<=50 {
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-top css ends ======*/

/*====== Padding-bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;

@while $i<=50 {
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-right css ends ======*/

/*====== Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;

@while $i<=50 {
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;

@while $i<=50 {
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;

@while $i<=50 {
  .m-r-#{$i} {
    margin-right: #{$i}px;
  }

  $i: $i+5;
}

/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i: 0;

@while $i<=10 {
  .b-r-#{$i} {
    border-radius: #{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border-radius css ends ======*/

/*====== Font-size css starts ======*/
$i: 12;

@while $i<=100 {
  .f-#{$i} {
    font-size: #{$i}px !important;
  }

  $i: $i+2;
}

/*====== Font-size css ends ======*/

/*====== Font-weight css starts ======*/
$i: 100,
300,
500,
400,
600,
700,
900;

@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}

/*====== Font-weight css ends ======*/

/*====== Font-style css starts ======*/
$i: normal,
italic,
oblique,
initial,
inherit;

@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}

/*====== Font-style css ends ======*/

/*====== Text-Decoration css starts ======*/

$i: overline,
line-through,
underline,
dashed,
blink,
dotted,
initial,
none,
solid,
wavy,
inherit,
double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/

/*====== Vertical-Align css starts ======*/
$i: baseline,
sub,
super,
top,
text-top,
middle,
bottom,
text-bottom,
initial,
inherit;

@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}

/*====== Vertical-Align css ends ======*/

/*====== Position css starts ======*/

$i: static,
absolute,
fixed,
relative,
initial,
inherit;

@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}

/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i: left,
right,
none;

@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}

/*====== Float css ends ======*/

/*====== Overflow css starts ======*/
$i: hidden,
visible,
auto;

@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}

/*====== Overflow css ends ======*/

/*====== Image-sizes css starts ======*/
$i: 10;

@while $i<=100 {
  .img-#{$i} {
    width: #{$i}px !important;
  }

  $i: $i+10;
}

/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$text-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);

  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}

.font-primary {
  color: var(--theme-color) !important;
}

.font-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Text css ends ===========*/

/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name: theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$label-color: $primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $label-name {
  $i: index($label-name, $var);

  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}

/*======= Label-color css ends  ======= */

/*======= Badge-color css starts  ======= */

$badge-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$badge-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $badge-name {
  $i: index($badge-name, $var);

  .badge-#{$var} {
    background-color: nth($badge-color, $i);

    @if ($badge-name=='light') {
      color: $theme-body-font-color !important;
    }
  }
}

.badge-primary {
  background-color: var(--theme-color) !important;
}

.badge-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Badge-color css end  ======= */

/*======= Background-color css starts  ======= */

$background-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$background-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);

  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }

  .bg-light-#{$var} {
    background-color: rgba(nth($background-color, $i), 0.2) !important;
    color: $white;
  }
}

.bg-primary {
  background-color: var(--theme-color) !important;
}

.bg-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning,
google-plus,
twitter,
linkedin,
fb;
$label-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color,
$google-plus,
$twitter,
$linkedin,
$fb;

@each $var in $text-name {
  $i: index($text-name, $var);

  .txt-#{$var} {
    color: nth($label-color, $i) !important;
    margin-right: 5px;
  }
}

.txt-primary {
  color: var(--theme-color) !important;
}

.txt-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Font-color css end  ======= */

/*======= Button-color css starts  ======= */
@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name} {
    background-color: $btn-color !important;
    border-color: $btn-color !important;

    @if ($btn-name=='info') {
      color: $white;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: $white;
      }
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem lighten($btn-color, 30%);
    }
  }
}

.btn-light {
  background-color: $inner-bg !important;
  border-color: $light-semi-gray !important;
}

/*======= Button-color css ends  ======= */
@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x {
    border-width: 2px;
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
      box-shadow: none;
    }
  }
}

/* outline buttons */
@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name} {
    border-color: $btn-color;
    color: $btn-color;
    background-color: transparent;

    &.disabled {
      color: $btn-color;
    }

    @if ($btn-name=='light') {
      color: $theme-body-font-color;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/* active button css */

@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active {
    background-color: darken($btn-color, 10%);
    border-color: darken($btn-color, 10%);
    box-shadow: none !important;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: darken($btn-color, 10%);
      border-color: darken($btn-color, 10%);
    }
  }
}

/* active button css end */

@each $btn-name,
$btn-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active {
    background-color: $btn-color;
    border-color: $btn-color;
    box-shadow: none !important;
    color: white;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      box-shadow: none !important;
      background-color: darken($btn-color, 10%) !important;
      border-color: darken($btn-color, 10%) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$border-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $border-name {
  $i: index($border-name, $var);

  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i) !important;
  }
}

/*======= Table-Border-Bottom-color css ends  ======= */

/*======= Table styling css starts  ======= */

$table-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$table-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $table-name {
  $i: index($table-name, $var);

  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);

    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }

    th,
    td {
      background-color: transparent;
    }
  }
}

/*======= Table styling css ends  ======= */

/*======= All-Borders-color css starts  ======= */

$b-name: primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$b-color: $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;

@each $var in $b-name {
  $i: index($b-name, $var);

  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }

  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }

  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }

  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }

  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}

/*======= All-Borders-color css ends  ======= */

/*====== Border width css starts ======*/
$i: 1;

@while $i<=10 {
  .border-#{$i} {
    border-width: #{$i}px !important;
  }

  $i: $i+1;
}

/*====== Border width css ends ======*/

.opacity-0 {
  opacity: 0;
}

.map-block {
  height: 350px;
  width: 100%;
}

.map-js-height {
  height: 500px;

  .btn-group {
    input {
      background-color: $primary-color;

      &+input {
        background-color: $secondary-color;
      }
    }
  }
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
    box-shadow: inset 0 0 6px $light-gray;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: $dark-color;

  pre {
    font-size: 100%;
  }
}

/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/

/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  font-weight: 400;
  z-index: 1;
  right: 0;
  background-color: $white !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/

/**====== Animation css start ======**/
.options {
  >div {
    color: $gray-60;
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid;
    margin: 0 8px 8px 0;
    transition: all 0.3s ease;

    &:hover {
      background-color: $primary-color;
      color: $white;
      transition: all 0.3s ease;
    }
  }
}

/**====== Animation css Ends ======**/

.modal-footer {
  flex-wrap: wrap;
}

.img-cropper {
  #putData {
    margin-bottom: 0;
  }

  .img-container {
    min-height: auto;
    margin-bottom: 0;
  }

  .docs-data {
    >.input-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .docs-preview {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.dropzone {
  .dz-preview {
    .dz-error-message {
      background: $white !important;
      color: $primary-color !important;
      border: 1px solid $primary-color;

      &:after {
        border-bottom: 6px solid $primary-color !important;
      }
    }
  }
}

.typeahead {
  .theme-form {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.editor-statusbar {
  border-bottom: 1px solid rgba($black, 0.125);
}

.page-builder {
  .ge-canvas {
    &.ge-editing {
      .row {
        padding: 30px;
        margin-bottom: 0;
        background-color: rgba($primary-color, 0.02);
      }
    }
  }

  .btn-screen {
    padding: 0 18px 0 0;
  }
}

.bg-overlay {
  &.active {
    height: 100vh;
    width: 100vw;
    background-color: rgba($black, 0.2);
    position: fixed;
    z-index: 6;
    top: 0;
  }
}

button {
  &:focus {
    outline: none !important;
  }
}

.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

@keyframes fadeIncustom {
  0% {
    opacity: 0;
    top: 50px;
  }

  75% {
    opacity: 0.6;
    top: 0;
  }

  100% {
    opacity: 1;
  }
}

.daterangepicker {
  select {

    &.monthselect,
    &.yearselect {
      border-color: $light-semi-gray;
      background-color: $white;
      font-size: 14px;
      color: $dark-gray;
      padding: 5px 10px;
      border-radius: 5px;
      width: 46%;
    }
  }
}

@media (min-width: 991px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
    box-shadow: inset 0 0 6px rgba($primary-color, 0.1);
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fdf9f9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
}

a,
.cursor {
  cursor: pointer;
  caret-color: transparent;
}

.entries-form {
  display: flex;
  align-items: center;
  gap: 7px;

  input {
    width: 85px;
    text-align: center;
  }
}

.tree-main-ul {
  div {
    cursor: pointer;
    caret-color: transparent;
  }
}

.rule-dropdown {
  background-color: #fafaf6;
}

.rule-edit-form {
  background-color: #fcfcf7;
  padding: 20px;
  margin: 20px 0 0;

  .form-control {
    background-color: $white;
  }
}

.shipping-creation {
  padding: 20px;
}

.active>.page-link,
.page-link.active {
  z-index: unset;
}

.card {
  position: unset;
}

.custom-ul {
  display: flex;
  gap: 6px;
}

.custom-row {
  .row {
    >div {
      &:first-child {
        width: 20%;

        @media (max-width: 576px) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 80%;

        @media (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }
}

.attribute-row {
  align-items: unset !important;

  .custom-row {
    .input-error {
      >div {
        margin-bottom: 0 !important;
      }
    }
  }

  .attribute-remove {
    a {
      margin-top: 36px;
      display: block;
    }
  }
}

.mt-custom {
  margin-top: 12px !important;
}

.close-icon {
  svg {
    width: 18px;
    height: 18px;
    fill: #dc3545;
  }
}

input {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


.row>* {
  padding-left: 4px;
  padding-right: 4px;
}