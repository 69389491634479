/**=====================
    Switch CSS Start
==========================**/
.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
	margin-bottom: 0;

	input {
		display: none;

		&:checked + .switch-state {
			background-color: var(--theme-color);

			&:before {
				transform: translateX(26px);
			}
		}

		&:focus + .switch-state {
			box-shadow: 0 0 1px var(--theme-color);
		}
	}

	.switch-state {
		border-radius: 20px;
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #f3f3f3;
		transition: 0.4s;

		&:before {
			@include pos;
			height: 16px;
			width: 16px;
			left: 4px;
			bottom: 4px;
			background-color: $white;
			transition: 0.4s;
			border-radius: 20px;
		}
	}

	&.switch-sm {
		width: 42px;
		height: 20px;
		.switch-state {
			&:before {
				height: 14px;
				width: 14px;
				left: 3px;
				bottom: 3px;
			}
		}
		input {
			&:checked {
				+ .switch-state {
					&:before {
						transform: translateX(23px);
					}
				}
			}
		}
	}
}
