/*! -----------------------------------------------------------------------------------
    Template Name: Fastkart Admin
    Template URI: http://admin.pixelstrap.com/Fastkart/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap
----------------------------------------------------------------------------------- !*/

@import './style.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-toastify/dist/ReactToastify.min.css';
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  @include flex_common;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.otpContainer {
  margin: 5% auto;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}

.is-active {
  color: teal;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.highlight-text {
  background-color: #c3f7f0;
}

.datatable-wrapper {
  position: relative;
}

.datatable-wrapper .table-loader {
  position: absolute;
  top: 0;
  left: 0;
  @include pseudowh;
  @include flex_common;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}
.hidden{
  display: none;
}
