/**=====================
     Success box CSS Start
==========================**/
.box-wrapper {
  position: relative;
}

.success-box {
  border: 1px solid $primary-color;
  padding: calc(15px + 25 * (100vw - 320px) / 1600);
  background-color: $white;
  border-radius: calc(8px + 4 * (100vw - 320px) / 1600);
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: $card-box-shadow;
  position: absolute;
  width: 100%;
  transform: translateY(-100%);
  top: -120px;

  svg {
    width: 40px;
    height: 40px;
    fill: $primary-color;
  }

  h4 {
    font-size: 18px;
    color: $primary-color;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $title-color;
    line-height: 1;
    margin-top: 5px;
  }
}

.user-round {
  @include flex_common;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #f0f0f0;
  margin: 0 auto;
  h4 {
    font-weight: 600;
  }

  img {
    width: 50px;
    height: auto;
    object-fit: cover;
  }
}
