/**=====================
     Radio CSS Start
==========================**/
.animate-chk {
    label {
        line-height: 1.6;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.radio_animated {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;

    &:before {
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: scale(0, 0);
        content: "";
        position: absolute;
        top: 0;
        left: 0.125rem;
        z-index: 1;
        width: 0.75rem;
        height: 0.75rem;
        background: var(--theme-color);
        border-radius: 50%;
    }

    &:after {
        content: "";
        position: absolute;
        top: -0.25rem;
        left: -0.125rem;
        width: 1.25rem;
        height: 1.25rem;
        background: $white;
        border: 2px solid $light-gray;
        border-radius: 50%;
    }

    &:checked:before {
        transform: scale(1, 1);
    }
}

.checkbox {
    label {
        display: inline-block;
        position: relative;
        padding-left: 16px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -16px;
            border: 1px solid $light-semi-gray;
            border-radius: 3px;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            margin-left: -20px;
            padding-left: 3px;
            padding-top: 1px;
            font-size: 11px;
            color: $theme-font-color;
        }
    }

    input[type="checkbox"] {
        opacity: 0;

        &:focus {
            outline-offset: -2px;

            &+label {
                &::before {
                    outline-offset: -2px;
                }
            }
        }
    }

    input[type="checkbox"] {
        &:checked {
            &+label {
                &::before {
                    font-family: $font-themify;
                    content: "\e64c";
                    text-align: center;
                    line-height: 1.2;
                }
            }
        }

        &:disabled {
            &+label {
                opacity: 0.65;

                &::before {
                    background-color: $light-color;
                    cursor: not-allowed;
                }
            }
        }
    }

    .checkbox-circle {
        label {
            &::before {
                border-radius: 50%;
            }
        }
    }

    .checkbox-inline {
        margin-top: 0;
    }
}

.m-squar {
    label {
        &::before {
            border-radius: 0;
        }
    }

    .checkbox-circle {
        label {
            &::before {
                border-radius: 50%;
            }
        }
    }

    .checkbox-inline {
        margin-top: 0;
    }
}

@each $btn-name,
$btn-color in (primary, var(--theme-color)),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
    .checkbox-#{$btn-name} {
        label {
            &::before {
                border-color: $btn-color;
            }
        }

        input[type="checkbox"] {
            &:checked {
                &+label {
                    &::before {
                        border-color: $btn-color;
                        color: $btn-color;
                    }

                    &::after {
                        color: $white;
                    }
                }
            }
        }
    }

    .checkbox-solid-#{$btn-name} {
        label {
            &:before {
                background-color: $btn-color;
                border-color: $btn-color;
                color: $white;
            }
        }

        input[type="checkbox"] {
            &:checked {
                &+label {
                    &::before {
                        background-color: $btn-color;
                        border-color: $btn-color;
                        color: $white;
                    }

                    &::after {
                        color: $white;
                    }
                }
            }
        }
    }

    .radio-#{$btn-name} {
        input[type="radio"] {
            &+label {
                &::before {
                    border-color: $btn-color;
                }

                &::after {
                    background-color: $btn-color;
                }
            }

            &:checked {
                &+label {
                    &::before {
                        border-color: $btn-color;
                    }

                    &::after {
                        background-color: $btn-color;
                    }
                }
            }
        }
    }
}

.m-checkbox-inline {
    .checkbox {
        display: inline-block;
    }

    .radio {
        display: inline-block;
    }

    label {
        margin-right: 20px;
    }
}

.radio {
    label {
        display: inline-block;
        position: relative;
        padding-left: 5px;
        cursor: pointer;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 19px;
            height: 19px;
            left: 0;
            margin-left: -20px;
            border: 1px solid $light-semi-gray;
            border-radius: 50%;
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
        }

        &::after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 9px;
            height: 9px;
            left: 5px;
            top: 5px;
            margin-left: -20px;
            border-radius: 50%;
            background-color: $dark-gray;
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }

    input[type="radio"] {
        opacity: 0;

        &:focus {
            &+label {
                &::before {
                    outline-offset: -2px;
                }
            }
        }

        &:checked {
            &+label {
                &::after {
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                }
            }
        }

        &:disabled {
            &+label {
                opacity: 0.65;

                &::before {
                    cursor: not-allowed;
                }
            }
        }
    }

    .radio-inline {
        margin-top: 0;
    }
}

#app-cover {
    display: table;
    counter-reset: button-counter;
    width: 100%;

    .toggle-button-cover {
        display: table-cell;
        position: relative;
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .button-cover {
        height: auto;
        background-color: $white;
        box-shadow: 0 10px 20px -8px $light-body-bg-color;
        border-radius: 4px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .knobs {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }

    .layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $white-ice;
        transition: 0.3s ease all;
        z-index: 1;
    }

    .button {
        position: relative;
        top: 50%;
        width: 100px;
        height: 35px;
        margin: 0;
        overflow: hidden;
    }

    .button {
        &.r {
            border-radius: 100px;

            .layer {
                border-radius: 100px;
            }
        }
    }

    .button.b2 {
        border-radius: 2px;
    }

    .checkbox {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;
    }

    #button-9 {
        .knobs {
            &:before {
                position: absolute;
                @include center(vertical);
                width: 30px;
                height: auto;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding-top: 4px;
                border-radius: 50%;
                transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
                content: "YES";
                left: 4px;
                color: $white;
                opacity: 0;
                z-index: 2;
            }

            &:after {
                position: absolute;
                @include center(vertical);
                width: 30px;
                height: auto;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding-top: 4px;
                border-radius: 50%;
                transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
                content: "NO";
                right: -24px;
                color: $white;
                z-index: 2;
                opacity: 0;
            }

            span {
                position: absolute;
                top: 4px;
                width: 30px;
                height: 30px;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                padding: 9px 4px;
                border-radius: 50%;
                transition: 0.4s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
                left: 4px;
                background-color: $dark-card-background;
                z-index: 1;
            }
        }

        .checkbox {
            &:checked {
                &+.knobs {
                    &:before {
                        left: -24px;
                    }

                    &:after {
                        right: 0;
                        opacity: 1;
                    }

                    span {
                        right: 0;
                        background-color: var(--theme-color);
                        left: unset;
                    }
                }

                ~.layer {
                    background-color: $skin-tutu;
                }
            }
        }
    }
}

.radio-section {
    label {
        margin-right: 20px;
        margin-bottom: 0;

        >input {
            display: none;
        }

        >i {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 5px;
            vertical-align: middle;
            border: 2px solid var(--theme-color);
            box-shadow: none;
            transition: 0.25s;
        }

        >input:checked {
            +i {
                background: var(--theme-color);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin-right: 16px;
    height: 100%;
    border: none;

    [dir="rtl"] & {
        margin-right: unset;
        margin-left: 16px;
    }

    &:before {
        @include pos;
        @include pseudowh($width: 12px, $height: 6px);
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        left: 5px;
        top: 5px;
        z-index: 1;
        border: 2px solid var(--theme-color);
        border-top-style: none;
        border-right-style: none;

        [dir="rtl"] & {
            left: unset;
            right: 5px;
        }
    }

    &:after {
        @include pos;
        width: 24px;
        height: 24px;
        top: -2px;
        left: -1px;
        background-color: #fff;
        border: 2px solid #eee;
        cursor: pointer;
        border-radius: 6px;

        [dir="rtl"] & {
            left: unset;
            right: -1px;
        }
    }

    &:checked {
        &:before {
            transform: rotate(-45deg) scale(1, 1);
        }
    }

    &:focus {
        box-shadow: none;
    }
}